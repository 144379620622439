import React, { Component, useContext, useState } from "react"
import styled from "styled-components"
import { getUser, isWindow, signOut } from "../components/handle-auth"
import Layout from "../components/layout"
import ContentBox from "../components/contentbox"
import Button from "../components/button"
import Input from "../components/input"
import Axios from "axios"
import { db, colors } from "../components/siteVars"
import Loader from "../components/loader"
import popupAlert from "../components/popup-alert"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"
import { navigate } from "gatsby"

const StyledAccountPage = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  h1 {
    margin-top: 0;
  }
  .init-change-password {
    margin-bottom: 1rem;
  }
  .abort-change-password {
    margin-right: 0.5rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const LoginPageLoader = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`

const AccountPage = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [changePassword, setChangePassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("")

  // state = {
  //   user: {},
  //   changePassword: false,
  //   currentPassword: "",
  //   newPassword: "",
  //   newPasswordRepeat: "",
  //   loading: true,
  // }

  //   changePassword = () => {
  //     this.setState({
  //       changePassword: !this.state.changePassword,
  //     })
  //   }

  //   onChange = e => {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //     })
  //   }

  const onSubmit = e => {
    // this.setState({
    //   loading: true,
    // })
    e.preventDefault()
    let token = false
    if (isWindow) token = window.localStorage.getItem("authToken")
    if (!token) {
      popupAlert("Access denied")
      //   this.setState({
      //     loading: true,
      //   })
      return
    }

    if (newPassword !== newPasswordRepeat) {
      popupAlert("New passwords do not match")
      // this.setState({
      //   loading: true,
      // })
      return
    }
    const body = {
      //   username: user.username,
      _id: user._id,
      //   email: user.email,
      passwordLocked:
        state.user.permissions === "user" ||
        state.user.permissions === "proUser"
          ? true
          : false,
      currentPassword: currentPassword,
      password: newPassword,
    }
    console.log(body)
    Axios.post(db.lambda + "users/updatePassword/" + token, body)
      .then(res => {
        // this.setState({
        //   loading: true,
        // })
        popupAlert(res.data)
        setTimeout(() => {
          dispatch({ type: "SIGN_OUT" })
          navigate("/")
          if (typeof window !== `undefined`) {
            window.localStorage.removeItem("authToken")
          }
        }, 1000)
      })
      .catch(err => {
        // this.setState({
        //   loading: true,
        // })
        popupAlert(err.response.data)
      })
  }

  const { user } = state
  const permissions = function () {
    switch (user.permissions) {
      case "user":
        return "Free user"
      case "proUser":
        return "Pro user"
      case "coach":
        return "Coach"
      case "admin":
        return "Admin"
      default:
        return ""
    }
  }
  return (
    <>
      {/* <LoginPageLoader className={this.state.loading ? "active" : ""}>
          <Loader dualRing color={colors.mediumGrey} />
        </LoginPageLoader> */}
      <Layout>
        <StyledAccountPage>
          <ContentBox className="user-info">
            <h1>Account information</h1>

            <p>
              <strong>Username: </strong>
              {user.username}
            </p>
            <p>
              <strong>Email: </strong>
              {user.email}
            </p>
            <p>
              <strong>Account type: </strong>
              {permissions()}
            </p>

            <>
              {user.passwordLocked ? (
                <div>
                  <Button disabled className="disabled-button">
                    Password is locked!
                  </Button>
                  <p>
                    This accout type can only change password once. <br />
                    Please{" "}
                    <a href="mailto:teamgym@katalysatorav.se">contact us </a>
                    to change your password again.
                  </p>
                </div>
              ) : (
                <Button
                  onClick={() => {
                    setChangePassword(!changePassword)
                  }}
                  className="init-change-password"
                >
                  Change password
                </Button>
              )}
            </>

            {changePassword ? (
              <div className="change-password">
                <p>
                  <strong>
                    {user.permissions === "user" ||
                    user.permissions === "proUser"
                      ? "Please note that this type of account type can only change password once."
                      : ""}
                  </strong>
                  <br />
                  <br />
                  After changing password you must log in again using your new
                  password.
                </p>
                <form onSubmit={onSubmit}>
                  <Input
                    type="password"
                    name="currentPassword"
                    value={currentPassword}
                    placeholder="Current password"
                    label="Current password"
                    onChange={e => {
                      setCurrentPassword(e.target.value)
                    }}
                  />
                  <Input
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    placeholder="New password"
                    label="New password"
                    onChange={e => {
                      setNewPassword(e.target.value)
                    }}
                  />
                  <Input
                    type="password"
                    name="newPasswordRepeat"
                    value={newPasswordRepeat}
                    placeholder="Repeat new password"
                    label="Repeat new password"
                    onChange={e => {
                      setNewPasswordRepeat(e.target.value)
                    }}
                  />
                  <Button
                    type="button"
                    color="red"
                    onClick={() => {
                      setChangePassword(!changePassword)
                    }}
                    className="abort-change-password"
                  >
                    Abort
                  </Button>
                  <Button color="green" type="submit">
                    Submit
                  </Button>
                </form>
              </div>
            ) : null}
          </ContentBox>
        </StyledAccountPage>
      </Layout>
    </>
  )
}

export default AccountPage

const popupAlert = content => {
  const alertBox = document.querySelector("#alertBox")
  const alertBoxContent = document.querySelector("#alertBoxContent")
  alertBox.classList.add("active")
  alertBoxContent.textContent = content
  setTimeout(() => {
    alertBox.classList.remove("active")
    setTimeout(() => {
      alertBoxContent.textContent = ""
    }, 200)
  }, 3000)
}

export default popupAlert
